

.form-title {
    margin-bottom: 1rem;
    color:rgb(15, 15, 138);
    font-size: xx-large;
    text-align: center;
    
  }
  
  .form-group {
    margin-bottom: 1rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    
    
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .form-group input:focus {
    border-color: rgb(15, 15, 138);
    box-shadow: 0 0 0 0.2rem rgb(15, 15, 138);
    outline: none;
  }
  
  .submit-btn {
    background-color: rgb(15, 15, 138);
    border: none;
    color: #d6d6d6;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .submit-btn:hover {
    background-color: rgb(15, 15, 138);
  }
  
  .imgVA {
    width: 10%;
    height: 10%;
    margin-left: 45%;
    margin-right: 45%;
    margin-top: 5%;
  }