.workordermodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Aligns modal to the bottom */
   
  }
  
  .workordermodal-content {
    background-color: #f8f8f8;
    width: 100%;
    height: 90vh; /* 90% of the viewport height */
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto; /* Allows scrolling if content is too long */
    position: relative;
    
  }
  