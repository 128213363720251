

.menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    height: 10vh;
    margin-left: 5vw;
    
  }
  
  .menu-item {
    margin-right: 20px;
    
    font-size: 1.5rem; /* Making the text fairly large */
    border: none;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .menu-item:hover {
    background-color: #e0e0e0;
  }
  
  .mainmenuline {
    border-top: 1px solid #3c3c3c;
  width: 94vw;
  margin-left: 3vw;
  }