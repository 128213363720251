.logout-btn {
    background-color: rgb(15, 15, 138);
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
}

.profileheader {
    color:rgb(15, 15, 138);
    font-size: x-large;
    align-self: center;
}

.profilefooterlines {
  border-top: 1px solid #3c3c3c;
  width: 33vw;
}


.profile {
    display: flex;
    flex-direction: column;
}