.App {
  text-align: center;
  
  
}

.content {
  background-color: white;

  display: grid;
  
}



