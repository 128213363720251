.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 100;
    align-items: center;
    justify-content: center;
    overflow: auto;
    
    
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    width: 90%;
    max-width: 400px;
    position: relative;
    border-radius: 0.25rem;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .modal-close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    
  }

  .modal-close-btn:hover {
    color: rgb(15, 15, 138);
  }
  
  .switch-form-btn {
    background-color: transparent;
    border: none;
    color: #8860d0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .switch-form-btn:hover {
    background-color: #5ab9ea;
  }