.customerlist {
    height: fit-content;
    width: 90vw;
    display: flex;
    justify-self: center;
    flex-direction: column;
    align-items: center;
}

.customerlistdiv {
    width: 100%;
}

.customerlistrow {
    height: 6vh;
    color: black;
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    align-items: center;
    
}
.customerlistrowscroll {
    height: 50vh;
    overflow-y: scroll;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
}

.customerlistrowscroll::-webkit-scrollbar {
    width: 0; /* Hide scrollbar in Webkit (Chrome, Safari) */
  }


.customerlistrow:hover {
    background-color: lightgray;
}

.customerlistrowdata {
    flex: 1;
    text-align: left; /* Align text to the left, you can change it as needed */
    padding-left: 10px;
}

/* If you have additional row data classes in your CustomerList, replicate the .customerlistrowdata style for each */

.customerlistfilters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    width: 100%;
}

.customerlistfiltersbutton {
    color: black;
    width: 7vw;
    height: 5vh;
    padding: 10px;
    margin-right: 4px;
    font-size: xx-small;
}

.customerlistlabels {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.customerlistlabelsheaders {
    flex: 1; /* Adjust the flex value based on your layout */
    text-align: left; /* Align text to the left, you can change it as needed */
    padding-left: 10px;
}

.newcustomerbutton {
    width: 8%;
    height: 8vh;
    background-color: rgb(15, 15, 138);
    color: white;
    border: 0px;
    border-radius: 10px;
    margin: 10px;
}

.newcustomerbutton:hover {
    background-color: rgb(7, 7, 74);
}

.statusfilter {
    display: flex;
    flex-direction: column;
    text-align: start;
    color: gray;
    margin: 10px;
    width: 8vw;
}

.statusselect {
    width: 8vw;
    height: 5vh;
    padding: 10px;
}

.statuslabel {
    height: 8vh;
    display: flex;
    flex-direction: column;
    text-align: start;
    color: gray;
    margin: 10px;
}
.searchlabel {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.searchbar {
    height: 5vh;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 10vw;
}

.customerlistlabelsheadersline {
    border-top: 2px solid #3c3c3c;
    width: 90vw;
}

.customerlistlabelsparent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
