.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 10vh;
    max-width: 100vw;
    
}

.imgHorizontallogo {
    max-width: 55%;
    max-height: 55%;
    margin: 1%;
}
.header-nav {
    display: flex;
    margin-right: 2%;
}

.header-login-btn {
    background-color: rgb(15, 15, 138);
    border: none;
    color: #d6d6d6;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
    
  }
  
  .header-login-btn:hover {
    background-color: #5ab9ea;
  }
  

  .header-user-info {
    background-color: white;
    border: 1px solid rgb(15, 15, 138);
    color: black;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }

  .header-user-info:hover {
    background-color: rgb(15, 15, 138);

  }