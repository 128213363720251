.workorderhistory {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Cover the entire parent */
    height: 100%; /* Cover the entire parent */
    background: white; /* Example semi-transparent background */
    z-index: 10; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }


  .workorderhistory ul {
    list-style-type: none;
    padding: 0;
    height: 50vh;
    overflow-y: scroll;
}

.workorderhistory li {
    margin-bottom: 15px;
    border-top: 2px solid black;
}

.workorderhistory button {
    margin-bottom: 10px;
}