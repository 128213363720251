.workorder {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    max-height: 75vh
}



.workorderform {
    display: flex;
    flex-direction: column;
    height: 75vh;
    
}

.labelcontact {
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: left;
    width: 20vw;
    
}
.labelimportantinfo {
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: left;
    width: 15vw;
}

.inputscontact {
    width: 20vw;
    height: 5vh;
    margin: 5px;
    margin-left: 0;
    border: 1px solid lightgray;
}

.inputsprice {
    width: 10vw;
    height: 5vh;
    margin: 5px;
    margin-left: 0;
    border: 1px solid lightgray;
}

.labeldetails {
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: left;
    width: 18vw;
    
}

.labeldetailslarge {
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: left;
    width: 30vw;
    
}

.inputsdetails {
    width: 18vw;
    height: 5vh;
    margin: 5px;
    margin-left: 0;
    border: 1px solid lightgray;

}

.inputsdetailslarge {
    width: 30vw;
    height: 15vh;
    margin: 5px;
    margin-left: 0;
    border: 1px solid lightgray;

}



.workorderheader {
    display: flex;
    justify-self: baseline;
}

.workordertop {
    display: flex;
    flex-direction: row;
    height: 25vh;
    
}

.workordercontact {
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 25vh;

}
.contactcolumn {
    display: flex;
    flex-direction: row;
}

.workorderimportantinfo {
    display: flex;
    flex-direction: row;
    width: 50vw;
    height: 25vh;
    
}

.workorderdetails {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
   

}

.workorderdetailssmall {
    display: flex;
    flex-direction: row;
}

.workorderdetailslarge {
    display: flex;
    flex-direction: row;
}

.savebuttonbanner {
    position: fixed; /* Changed from absolute to fixed */
    bottom: 0;
    left: 0;
    width: 100%; /* Ensure it spans the full width */
    background-color: darkgray;
    height: 7vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center; 
    justify-content: space-between; 

}
.savebutton {
    padding: 10px;
    width: 15%;
    background-color: rgb(15, 15, 138);
    color: white;
    border: 0px;
    border-radius: 10px;
    justify-self: end;
    margin: 5px;
}

.savebutton:hover {
    background-color: rgb(7, 7, 74);

}


.historybutton {
    padding: 10px;
    width: 15%;
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 10px;
    
    margin: 5px;

}

.historybutton:hover {
    background-color: grey;
}